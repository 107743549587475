import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { AnimatePresence } from 'framer-motion'

import * as common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import * as icons from '../icons'
import { Button } from '../button'
import { CasinoGameTile } from '../casino-game-tile'
import { GameTileAnimation } from '../game-tile-animation'
import { GameTileSection } from '../game-tile-section'
import { Parallax } from '../parallax'
import { mapIndexed } from '../map-indexed'

export function LandingPageCategory(props) {
  const i18n = useI18n()

  React.useEffect(() => {
    props.onFetch()
  }, [])

  if (R.isEmpty(props.sections)) {
    return (
      <common.Box
        display="flex"
        justifyContent="center"
        alignSelf="center"
        fontSize={['48px', '64px']}
        pt={3}
      >
        <icons.Spinner delay />
      </common.Box>
    )
  }

  return (
    <React.Fragment>
      {R.map(
        section =>
          R.not(R.isEmpty(section.games)) && (
            <common.Box key={`landing-page.-${section.key}`} pb="3">
              <GameTileSection
                id={section.key}
                columns={R.length(section.games)}
                layout={section.layout}
                sectionTitle={`landing-page.${section.key}`}
                size={section.tileSize}
              >
                {data => {
                  return (
                    <AnimatePresence>
                      {mapIndexed(
                        (gameId, index) => (
                          <GameTileAnimation
                            key={`landing-page-${gameId}`}
                            delay={(index % 24) * 0.04}
                          >
                            <ReactRouter.Link to={`/casino/games/${gameId}`}>
                              <Parallax>
                                <CasinoGameTile
                                  id={gameId}
                                  size={section.tileSize}
                                />
                              </Parallax>
                            </ReactRouter.Link>
                          </GameTileAnimation>
                        ),
                        R.take(data.visibleGames, section.games)
                      )}
                    </AnimatePresence>
                  )
                }}
              </GameTileSection>
              {section.layout === 'grid' &&
                section.totalGamesCount !== R.length(section.games) && (
                  <common.Box display="flex" justifyContent="center" pt="4">
                    <Button
                      onClick={() =>
                        props.onLoadMore(section.id, R.length(section.games))
                      }
                    >
                      {i18n.translate('landing-page.search-result.show-more')}
                    </Button>
                  </common.Box>
                )}
            </common.Box>
          ),
        props.sections
      )}
    </React.Fragment>
  )
}

LandingPageCategory.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      layout: PropTypes.oneOf(['row', 'grid']),
      tileSize: PropTypes.oneOf(['small', 'medium', 'large']),
      totalGamesCount: PropTypes.number.isRequired,
      games: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  onFetch: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func,
}
